<template>
  <div id="customer-order-detail">
    <Navigation />
    <v-main class="content mb-9">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="ดูรายการออเดอร์" />

            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>#</th>
                  <th>ชื่อสินค้า</th>
                  <th>จำนวน</th>
                </tr>
                <tr
                  class="table-body"
                  v-for="(data, index) in customerProductOrders"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.product_name }}</td>
                  <td>{{ data.total_quantity }}</td>
                </tr>
              </table>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import HeaderPage from '@/components/HeaderPage.vue';
import FooterAdmin from '@/components/FooterAdmin.vue';

import CustomerService from '@/services/Customer.service';
import Swal from 'sweetalert2';
export default {
  name: 'customer-order-detail',
  components: {
    Navigation,
    HeaderPage,
    FooterAdmin,
  },
  data() {
    return {
      customerProductOrders: [],
    };
  },
  created() {
    this.getCustomerProductOrders();
  },
  methods: {
    async getCustomerProductOrders() {
      try {
        const response = await CustomerService.customerProductOrders(
          this.$route.params.customerId
        );
        this.customerProductOrders = response.customerProductOrders;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style></style>
